import ResetStep1 from 'components/login/reset/ResetStep1';
import ResetStep2 from 'components/login/reset/ResetStep2';
import ContentModal from 'components/modal/ContentModal';
import React, {useState} from 'react';

interface ResetModalProps {
  onClose: () => void;
}

const ResetModal = ({onClose}: ResetModalProps) => {
  const [step, setStep] = useState(1);

  const renderStep = () => {
    switch (step) {
      case 1:
        return <ResetStep1 onClickNext={() => setStep(2)} />;
      case 2:
        return <ResetStep2 />;
      default:
        return null;
    }
  };

  return (
    <ContentModal closePosition='top-[34px] right-[34px]' onClose={onClose}>
      {renderStep()}
    </ContentModal>
  );
};

export default ResetModal;
