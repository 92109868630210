import {useQuery} from '@tanstack/react-query';
import {useRequest} from 'apis/core/Axios';
import {useInfoStore} from 'store/useInfoStore';

export const getMemberNotiCheckQueryKey = ['getMemberNotiCheck'];

export const useGetMemberNotiCheck = () => {
  const {accessToken} = useInfoStore();
  const {request} = useRequest();
  return useQuery({
    queryKey: getMemberNotiCheckQueryKey,
    queryFn: async () => {
      const res = await request.get('/member/notification/check');
      return res;
    },
    enabled: accessToken !== '',
  });
};
