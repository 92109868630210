import React from 'react';
import {ClipLoader} from 'react-spinners';

const FixedLoading = () => {
  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center bg-black/50'>
      <ClipLoader color='#FF2D2E' loading={true} size={150} />
    </div>
  );
};

export default FixedLoading;
