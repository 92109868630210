import {QueryClient} from '@tanstack/react-query';
import {getMemberNotiCheckQueryKey} from 'apis/handler/member/useGetMemberNotiCheck';
import {create} from 'zustand';
import {persist} from 'zustand/middleware';

export const INFO_STORE_KEY = 'info-store';

// TypeScript 인터페이스 정의
interface InfoState {
  accessToken: string;
  firstName: string;
  lastName: string;
  email: string;
  setAccessToken: (accessToken: string) => void;
  setInfo: (info: {accessToken: string; firstName: string; lastName: string; email: string}) => void;
  setFirstName: (firstName: string) => void;
  setLastName: (lastName: string) => void;
  setEmail: (email: string) => void;
  resetInfo: (queryClient: QueryClient) => void;
  getAccessToken: () => string;
  getFirstName: () => string;
  getLastName: () => string;
  getEmail: () => string;
  getInfo: () => {firstName: string; lastName: string; email: string};
}

// Zustand Store 생성
export const useInfoStore = create(
  persist<InfoState>(
    (set, get) => ({
      accessToken: '',
      firstName: '',
      lastName: '',
      email: '',

      setAccessToken: accessToken => set({accessToken}),
      setFirstName: firstName => set({firstName}),
      setLastName: lastName => set({lastName}),
      setEmail: email => set({email}),
      setInfo: ({accessToken, firstName, lastName, email}) => set({accessToken, firstName, lastName, email}),
      resetInfo: queryClient => {
        queryClient.invalidateQueries({
          queryKey: getMemberNotiCheckQueryKey,
        });
        set({
          accessToken: '',
          firstName: '',
          lastName: '',
          email: '',
        });
      },
      getAccessToken: () => get().accessToken,
      getFirstName: () => get().firstName,
      getLastName: () => get().lastName,
      getEmail: () => get().email,
      getInfo: () => ({
        firstName: get().firstName,
        lastName: get().lastName,
        email: get().email,
      }),
    }),
    {
      name: INFO_STORE_KEY,
    },
  ),
);
