import React from 'react';
import {ClipLoader} from 'react-spinners';

const LoadingSpinner = ({isMini}: {isMini?: boolean}) => {
  return (
    <div className='m-auto'>
      <ClipLoader color='#FF2D2E' loading={true} size={isMini ? 20 : 150} />
    </div>
  );
};

export default LoadingSpinner;
