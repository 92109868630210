import {useMutation} from '@tanstack/react-query';
import {useRequest} from 'apis/core/Axios';
import {mutationProps} from 'apis/core/commonSchema';

export const usePostEmailCertification = ({onSuccess, onError}: mutationProps) => {
  const {request} = useRequest();
  return useMutation({
    mutationFn: async (email: string) => {
      const res = await request.post('/auth/member/email-certification', {email});
      return res;
    },
    onSuccess: data => {
      onSuccess && onSuccess(data);
    },
    onError: error => {
      onError && onError(error);
    },
  });
};
