import {JWT_KEY} from '../config/constant';
import SHA256 from 'crypto-js/sha256';

/**
 * @param {string} value 입력값 공백 제거
 * @param {string} type ENG: 영문만, NUM: 숫자만
 * @returns {string}
 */
export const removeWhitespace = (value: string, type?: 'ENG' | 'NUM') => {
  if (type === 'ENG') {
    return value.replace(/[^a-zA-Z]/g, '');
  } else if (type === 'NUM') {
    return value.replace(/[^0-9]/g, '');
  } else {
    return value.replace(/\s/g, '');
  }
};

/**
 * @param {string} email 이메일 정규식 유효성 검사
 * @returns {boolean}
 */
export const isValidEmail = (email: string) => {
  return email.match(/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,4}$/i) !== null;
};

/**
 * @param {string} password 비밀번호 정규식 유효성 검사
 * @returns {boolean}
 */
export const isValidPassword = (password: string): boolean => {
  // 정규식 조건:
  // - 최소 8글자 이상
  // - 소문자 하나 이상
  // - 대문자 하나 이상
  // - 숫자 하나 이상 포함
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  return regex.test(password); // `.test`는 일치 여부를 반환
};

/**
 * @param {string} phoneNumber 전화번호 정규식 유효성 검사
 * @returns {boolean}
 */
export const isValidPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.match(/^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/);
};

/**
 * @param {string} input 전화번호 형식으로 정규식 변환
 * @returns {string|undefined}
 */
export const formatPhoneNumber = (input?: string) => {
  return input
    ?.replace(/[^0-9]/g, '')
    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
    .replace(/(-{1,2})$/g, '');
};

export const getJwt = () => window.localStorage.getItem(JWT_KEY) ?? '';

export const saveJwt = (jwt: string) => {
  window.localStorage.setItem(JWT_KEY, jwt);
};

export const clearJwt = () => {
  window.localStorage.removeItem(JWT_KEY ?? '');
};

export const sha256 = (message: string) => {
  return SHA256(message).toString();
};
