import Button from 'components/button/Button';
import DropdownContainer from 'components/input/DropdownContainer';
import InputContainer from 'components/input/InputContainer';
import ContentModalHeader from 'components/modal/ContentModalHeader';
import {COUNTRY_CODE_OPTIONS, DAY_OPTIONS_BY_MONTH_AND_YEAR, MONTH_OPTIONS, YEAR_OPTIONS} from 'config/constant';
import dayjs from 'dayjs';
import React, {useState} from 'react';
import {removeWhitespace} from 'utils/utility';

interface FindEmailStep1Props {
  tryFindEmail: (form: {
    firstName: string;
    lastName: string;
    birth: string;
    countryCode: string;
    phoneNumber: string;
  }) => void;
}

const FindEmailStep1 = ({tryFindEmail}: FindEmailStep1Props) => {
  const [form, setForm] = useState<{
    firstName: string;
    lastName: string;
    birth: string;
    countryCode: string;
    phoneNumber: string;
  }>({
    firstName: '',
    lastName: '',
    birth: '',
    countryCode: '',
    phoneNumber: '',
  });
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');
  const [formError, setFormError] = useState<{
    firstName: string;
    lastName: string;
    birth: string;
    countryCode: string;
    phoneNumber: string;
  }>({
    firstName: '',
    lastName: '',
    birth: '',
    countryCode: '',
    phoneNumber: '',
  });

  const onChangeName = (field: string, value: string) => {
    setFormError(prevFormError => ({...prevFormError, [field]: ''}));
    setForm(prevForm => ({...prevForm, [field]: removeWhitespace(value, 'ENG')}));
  };

  const onChangePhoneNumber = (value: string) => {
    setFormError(prevFormError => ({...prevFormError, phoneNumber: ''}));
    setForm(prevForm => ({...prevForm, phoneNumber: removeWhitespace(value, 'NUM')}));
  };

  const findEmailHandler = () => {
    if (form.firstName === '') {
      setFormError(prevFormError => ({...prevFormError, firstName: 'Please enter your first name'}));
      return;
    }

    if (form.lastName === '') {
      setFormError(prevFormError => ({...prevFormError, lastName: 'Please enter your last name'}));
      return;
    }

    if (month === '' || day === '' || year === '') {
      setFormError(prevFormError => ({...prevFormError, birth: 'Please select Date of Birth'}));
      return;
    }
    const birth = dayjs(`${year}-${month}-${day}`).format('YYYY-MM-DD');
    setForm(prevForm => ({...prevForm, birth}));

    if (form.countryCode === '') {
      setFormError(prevFormError => ({...prevFormError, countryCode: 'Please select Country Code'}));
      return;
    }

    if (form.phoneNumber === '') {
      setFormError(prevFormError => ({...prevFormError, phoneNumber: 'Please enter Phone Number'}));
      return;
    }

    tryFindEmail({...form, birth});
  };

  return (
    <div className='m-[30px] mb-[50px] flex w-[408px] flex-col gap-y-11'>
      <div className='flex w-full flex-col gap-y-6'>
        <ContentModalHeader
          src='/images/illusts/il_mail.png'
          title='Find Email'
          description='Please enter your info to find your email adress'
        />

        <div className='mt-2.5 flex w-full flex-col gap-y-px'>
          <div className='flex w-full items-center gap-x-3.5'>
            <InputContainer
              className='flex-1'
              label='First Name'
              placeholder='Enter your first name in English'
              value={form.firstName}
              onChange={e => onChangeName('firstName', e.target.value)}
              error={formError.firstName}
            />
            <InputContainer
              className='flex-1'
              label='Last Name'
              placeholder='Enter your last name in English'
              value={form.lastName}
              onChange={e => onChangeName('lastName', e.target.value)}
              error={formError.lastName}
            />
          </div>
          <div className='flex w-full flex-col gap-y-2'>
            <span className='text-c3'>Date of Birth</span>
            <div className='flex w-full gap-x-3.5'>
              <DropdownContainer
                className='flex-1'
                placeholder='Month'
                value={month}
                setValue={value => {
                  setFormError(prevFormError => ({...prevFormError, birth: ''}));
                  setMonth(value);
                }}
                options={MONTH_OPTIONS}
                error=''
              />
              <DropdownContainer
                className='flex-1'
                placeholder='Date'
                value={day}
                setValue={value => {
                  setFormError(prevFormError => ({...prevFormError, birth: ''}));
                  setDay(value);
                }}
                isDisabled={month === ''}
                options={DAY_OPTIONS_BY_MONTH_AND_YEAR(month, year)}
                error=''
              />
              <DropdownContainer
                className='flex-1'
                placeholder='Year'
                value={year}
                initialValue='2000'
                setValue={value => {
                  setFormError(prevFormError => ({...prevFormError, birth: ''}));
                  setYear(value);
                  setDay('');
                }}
                options={YEAR_OPTIONS}
                error=''
              />
            </div>
            <span className='-mt-6 h-4 text-[11px] text-error'>{formError.birth}</span>
          </div>
          <div className='flex w-full gap-x-3.5'>
            <DropdownContainer
              className='flex-1'
              label='Country Code'
              value={form.countryCode}
              setValue={value => {
                setFormError(prevFormError => ({...prevFormError, countryCode: ''}));
                setForm(prevForm => ({...prevForm, countryCode: value}));
              }}
              options={COUNTRY_CODE_OPTIONS}
              error={formError.countryCode}
            />
            <InputContainer
              className='flex-1'
              placeholder='Enter numbers only'
              label='Phone Number'
              value={form.phoneNumber}
              onChange={e => onChangePhoneNumber(e.target.value)}
              error={formError.phoneNumber}
            />
          </div>
        </div>
      </div>
      <Button text='Find my email' shape='SQUARE' onClick={findEmailHandler} />
    </div>
  );
};

export default FindEmailStep1;
