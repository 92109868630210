import Button from 'components/button/Button';
import React, {useState, useEffect} from 'react';
import {ReactComponent as InfoIcon} from 'assets/icons/ic_info.svg';
import InputContainer from 'components/input/InputContainer';
import {isValidEmail, removeWhitespace} from 'utils/utility';
import ContentModalHeader from 'components/modal/ContentModalHeader';
import {usePostEmailCertification} from 'apis/handler/auth/usePostEmailCertification';
import FixedLoading from 'components/common/FixedLoading';

interface SignupStep1Props {
  form: {
    firstName: string;
    lastName: string;
    email: string;
  };
  onInputChange: (field: string, value: string) => void;
  onClickNext: () => void;
}

const SignupStep1 = ({onClickNext, form, onInputChange}: SignupStep1Props) => {
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');

  const {mutateAsync: trySendEmailCertification, isPending} = usePostEmailCertification({
    onSuccess: data => {
      console.log(data);
      onClickNext();
    },
    onError: error => {
      if (error === '중복된 이메일입니다.') {
        alert('An account already exists with the provided email address.');
      } else {
        alert(error);
      }
    },
  });

  const handleNameChange = (field: string, value: string) => {
    setFirstNameError('');
    setLastNameError('');

    onInputChange(field, removeWhitespace(value, 'ENG'));
  };

  const handleEmailChange = (field: string, value: string) => {
    setEmailError('');

    onInputChange(field, removeWhitespace(value));
  };

  const handleNext = async () => {
    if (form.firstName === '') {
      setFirstNameError('Please enter your first name');
      return;
    }

    if (form.lastName === '') {
      setLastNameError('Please enter your last name');
      return;
    }

    if (form.email === '') {
      setEmailError('Please enter your email');
      return;
    }

    if (!isValidEmail(form.email)) {
      setEmailError('Please enter a valid email');
      return;
    }

    try {
      await trySendEmailCertification(form.email);
    } catch (error) {
      console.error(error);
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleNext();
    }
  };

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'begin_sign_up',
      });
    }
  }, []);

  return (
    <div className='mx-45 my-30 flex w-[309px] flex-col gap-y-6.25'>
      <div className='flex w-full flex-col gap-y-4'>
        <ContentModalHeader
          src='/images/illusts/il_key.png'
          title='Sign up'
          description='Please enter your personal info'
        />
        <div className='flex w-full flex-col gap-y-px'>
          <InputContainer
            label='First Name (EN)'
            placeholder='Enter your first Name in english'
            error={firstNameError}
            value={form.firstName}
            onChange={e => handleNameChange('firstName', e.target.value)}
          />
          <InputContainer
            label='Last Name (EN)'
            placeholder='Enter your last name in english'
            error={lastNameError}
            value={form.lastName}
            onChange={e => handleNameChange('lastName', e.target.value)}
          />
          <InputContainer
            label='Email Address'
            placeholder='Enter your email address'
            error={emailError}
            value={form.email}
            onKeyPress={onKeyPress}
            onChange={e => handleEmailChange('email', e.target.value)}
          />
        </div>
        <div className='-mt-4 flex w-full gap-x-1.5 pt-px text-c2 text-primary'>
          <InfoIcon className='shrink-0' width={21} height={21} />
          <p className='flex-1 text-wrap'>Please enter your name in English as it appears on your passport.</p>
        </div>
      </div>
      <Button text='Continue' shape='SQUARE' onClick={handleNext} disabled={isPending} />
      {isPending && <FixedLoading />}
    </div>
  );
};

export default SignupStep1;
