import {useModal} from 'context/ModalContext';
import FindEmailModal from 'pages/login/FindEmailModal';
import LoginModal from 'pages/login/LoginModal';
import PasswordModal from 'pages/login/PasswordModal';
import ResetModal from 'pages/login/ResetModal';
import SignupModal from 'pages/login/SignupModal';
import React from 'react';

const Modals = () => {
  const {modalType, closeModal, MODAL_TYPES} = useModal();

  if (modalType === MODAL_TYPES.NONE) return null;

  const renderModal = () => {
    switch (modalType) {
      case MODAL_TYPES.LOGIN:
        return <LoginModal onClose={closeModal} />;
      case MODAL_TYPES.SIGNUP:
        return <SignupModal onClose={closeModal} />;
      case MODAL_TYPES.FIND_EMAIL:
        return <FindEmailModal onClose={closeModal} />;
      case MODAL_TYPES.PASSWORD:
        return <PasswordModal onClose={closeModal} />;
      case MODAL_TYPES.RESET:
        return <ResetModal onClose={closeModal} />;
      default:
        return null;
    }
  };

  return <div className='fixed inset-0 z-20 flex items-center justify-center bg-black/50'>{renderModal()}</div>;
};

export default Modals;
