import React from 'react';

const PasswordStep2 = () => {
  return (
    <div className='mx-45 my-30 flex w-[309px] flex-col items-center gap-y-5'>
      <img src='/images/illusts/il_mail.png' alt='password' className='h-[54px] w-[54px] object-cover' />
      <div className='flex w-full flex-col items-center gap-y-1.25'>
        <h1 className='text-t4'>Reset Password</h1>
        <p className='text-center text-c2 text-gray-40'>
          An email to reset your password has been
          <br />
          sent to your email address.
          <br />
          Please check your inbox.
        </p>
      </div>
    </div>
  );
};

export default PasswordStep2;
