import PasswordStep1 from 'components/login/password/PasswordStep1';
import PasswordStep2 from 'components/login/password/PasswordStep2';
import ContentModal from 'components/modal/ContentModal';
import React, {useState} from 'react';

interface PasswordModalProps {
  onClose: () => void;
}

const PasswordModal = ({onClose}: PasswordModalProps) => {
  const [step, setStep] = useState(1);

  const renderStep = () => {
    switch (step) {
      case 1:
        return <PasswordStep1 onClickNext={() => setStep(2)} />;
      case 2:
        return <PasswordStep2 />;
      default:
        return null;
    }
  };

  return (
    <ContentModal closePosition='top-[23px] right-[33px]' onClose={onClose}>
      {renderStep()}
    </ContentModal>
  );
};

export default PasswordModal;
