import {findEmailResponseType} from 'apis/handler/auth/authSchema';
import Button from 'components/button/Button';
import InputContainer from 'components/input/InputContainer';
import ContentModalHeader from 'components/modal/ContentModalHeader';
import {useModal} from 'context/ModalContext';
import React from 'react';

interface FindEmailStep2Props {
  data?: findEmailResponseType;
}

const FindEmailStep2 = ({data}: FindEmailStep2Props) => {
  const {openModal, MODAL_TYPES} = useModal();

  const handleLogin = () => {
    openModal(MODAL_TYPES.LOGIN);
  };

  return (
    <div className='m-30 mb-50 flex w-[408px] flex-col gap-y-30'>
      <div className='flex w-full flex-col gap-y-6'>
        <ContentModalHeader src='/images/illusts/il_mail.png' title='Find Email' />
        <div className='flex w-full flex-col gap-y-px'>
          {!data ? (
            <div>There is no account registered.</div>
          ) : (
            <>
              <InputContainer label='Name' defaultValue={`${data.firstName} ${data.lastName}`} disabled />
              <InputContainer label='Phone number' defaultValue={data.phoneNumber} disabled />
              <InputContainer label='Email' defaultValue={data.email} disabled />
            </>
          )}
        </div>
      </div>
      <Button text='Login' shape='SQUARE' onClick={handleLogin} />
    </div>
  );
};

export default FindEmailStep2;
