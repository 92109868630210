import React from 'react';

interface ContentModalHeaderProps {
  src: string;
  title: string;
  description?: string;
}

const ContentModalHeader = ({src, title, description}: ContentModalHeaderProps) => {
  return (
    <div className='flex w-full flex-col items-center gap-y-1.5'>
      <img src={src} alt={title.toLowerCase()} className='h-[54px] w-[54px] object-cover' />
      <div className='flex w-full flex-col items-center gap-y-1'>
        <h1 className='text-t4'>{title}</h1>
        {description && (
          <span className='w-full whitespace-pre-wrap break-words text-center text-c2 text-gray-40'>{description}</span>
        )}
      </div>
    </div>
  );
};

export default ContentModalHeader;
