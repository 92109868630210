import {loginResponseType} from 'apis/handler/auth/authSchema';
import {usePostLogin} from 'apis/handler/auth/usePostLogin';
import Button from 'components/button/Button';
import FixedLoading from 'components/common/FixedLoading';
import InputContainer from 'components/input/InputContainer';
import ContentModal from 'components/modal/ContentModal';
import ContentModalHeader from 'components/modal/ContentModalHeader';
import {useModal} from 'context/ModalContext';
import React, {useState} from 'react';
import {useInfoStore} from 'store/useInfoStore';
import {useRouteChangeHandler} from 'utils/hook';
import {removeWhitespace, sha256} from 'utils/utility';

interface LoginModalProps {
  onClose: () => void;
}

const LoginModal = ({onClose}: LoginModalProps) => {
  const {setInfo} = useInfoStore();
  const {openModal, MODAL_TYPES} = useModal();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const {handleLoginSuccess} = useRouteChangeHandler();

  const {mutateAsync: tryLogin, isPending} = usePostLogin({
    onSuccess: (data: loginResponseType) => {
      console.log(data);

      // 사용자 정보 저장
      setInfo({accessToken: data.accessToken, email: data.email, firstName: data.firstName, lastName: data.lastName});

      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'login',
          user_signup_date: data.createdAt, // API 응답에서 받아와야 함
          user_nationality: data.nationality, // API 응답에서 받아와야 함
          user_occupation: data.occupationType, // API 응답에서 받아와야 함
          user_visa: data.visaType, // API 응답에서 받아와야 함
          user_status: 'active',
          user_id: sha256(data.email), // API 응답에서 UUID를 받아와야 함
        });
      }

      handleLoginSuccess();
    },
    onError: error => {
      if (error === '일치하는 유저가 없습니다.') {
        setEmailError('No registered email address found.');
      } else {
        setPasswordError('Wrong password.');
      }
    },
  });

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailError('');
    setEmail(removeWhitespace(e.target.value));
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordError('');
    setPassword(removeWhitespace(e.target.value));
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    if (email === '') {
      setEmailError('Email is required');
      return;
    }

    if (password === '') {
      setPasswordError('Password is required');
      return;
    }

    try {
      await tryLogin({email, password});
    } catch (error) {
      console.error(error);
    }
  };

  const handleFindEmail = () => {
    openModal(MODAL_TYPES.FIND_EMAIL);
  };

  const handleResetPassword = () => {
    openModal(MODAL_TYPES.PASSWORD);
  };

  const handleSignup = () => {
    openModal(MODAL_TYPES.SIGNUP);
  };

  return (
    <ContentModal onClose={onClose} closePosition='right-[23px] top-[18px]'>
      <div className='m-[30px] flex w-[291px] flex-col gap-y-[50px]'>
        <div className='flex w-full flex-col gap-y-[18px]'>
          <div className='flex w-full flex-col gap-y-5'>
            <ContentModalHeader src='/images/illusts/il_key.png' title='Log in or Sign up' />
            <div className='flex w-full flex-col gap-y-0.5'>
              <InputContainer
                label='Email Address'
                placeholder='Enter your email address'
                value={email}
                onChange={onChangeEmail}
                error={emailError}
              />
              <InputContainer
                label='Password'
                type='password'
                placeholder='Enter your password'
                value={password}
                onChange={onChangePassword}
                onKeyDown={onKeyPress}
                error={passwordError}
              />
            </div>
          </div>
          <div className='flex w-full flex-col items-center gap-y-[13px]'>
            <div className='text-c4 text-gray-40'>
              <span className='cursor-pointer underline-offset-2 hover:underline' onClick={handleFindEmail}>
                Find email
              </span>{' '}
              |{' '}
              <span className='cursor-pointer underline-offset-2 hover:underline' onClick={handleResetPassword}>
                Reset password
              </span>
            </div>
            <Button text='Login' shape='SQUARE' onClick={handleLogin} disabled={isPending} />
          </div>
          {isPending && <FixedLoading />}
        </div>
        <div className='w-full text-center text-c2'>
          {`Don't have an account? `}
          <span className='ml-1 cursor-pointer text-primary underline-offset-2 hover:underline' onClick={handleSignup}>
            Sign up
          </span>
        </div>
      </div>
    </ContentModal>
  );
};

export default LoginModal;
