import Button from 'components/button/Button';
import React from 'react';
import {useRouteChangeHandler} from 'utils/hook';

interface SignupStep5Props {
  onClose: () => void;
}

const SignupStep5 = ({onClose}: SignupStep5Props) => {
  const {handleRouteChange} = useRouteChangeHandler();

  const handleGoCheckBookingGuide = () => {
    handleRouteChange('/guide');
    onClose();
  };

  return (
    <div className='mx-45 my-10 flex w-[309px] flex-col items-center justify-center gap-y-6.25'>
      <div className='flex w-full flex-col items-center gap-y-1.5'>
        <img src='/images/illusts/il_check.png' alt='signup' className='h-[54px] w-[54px] object-cover' />
        <div className='flex w-full flex-col items-center gap-y-2'>
          <h1 className='text-t4'>Welcome to Checkmate!</h1>
          <span className='w-full whitespace-normal break-words text-center text-c2 text-gray-40'>{`Find out how to find rooms in Korea using\nCheckmate in the booking guide.`}</span>
        </div>
      </div>
      <Button text='Go check booking guide' shape='SQUARE' onClick={handleGoCheckBookingGuide} />
    </div>
  );
};

export default SignupStep5;
