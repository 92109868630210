import {occupationType, visaType} from 'apis/handler/auth/authSchema';
import {furnitureType, roomType} from 'apis/handler/listing/listingSchema';
import {COUNTRY_CODE_OPTIONS} from 'config/constant';
import {removeWhitespace} from './utility';
import stationData from './subway.json';

/**
 *
 * @param occupation 유저가 보는 occupation
 * @returns 서버에 보내는 occupation
 */
export const convertOccupationToRequest = (occupation: string): occupationType => {
  switch (occupation) {
    case 'Undergraduate Student':
      return 'UNDERGRADUATE';
    case 'Graduate Student':
      return 'GRADUATE';
    case 'Exchange Student':
      return 'EXCHANGE';
    case 'Education/Academic Research':
      return 'EDUCATION';
    case 'IT/Software/Engineering':
      return 'IT_SOFTWARE_ENGINEERING';
    case 'Manufacture/Agriculture':
      return 'MANUFACTURE_AGRICULTURE';
    case 'Arts/Design':
      return 'ARTS_DESIGN';
    case 'Finance/Banking':
      return 'FINANCE_BANKING';
    case 'Public Service/Government':
      return 'PUBLIC_SERVICE';
    case 'Healthcare/Medical Service':
      return 'HEALTHCARE_MEDICAL';
    case 'Business/Sales':
      return 'BUSINESS_SALES';
    case 'Legal/Consulting':
      return 'LEGAL_CONSULTING';
    case 'Others':
      return 'OTHERS';
    default:
      return 'OTHERS';
  }
};

/**
 *
 * @param occupation 서버에서 받는 occupation
 * @returns 유저가 보는 occupation
 */
export const convertRequestToOccupation = (occupationType: string): string => {
  switch (occupationType) {
    case 'UNDERGRADUATE':
      return 'Undergraduate Student';
    case 'GRADUATE':
      return 'Graduate Student';
    case 'EXCHANGE':
      return 'Exchange Student';
    case 'EDUCATION':
      return 'Education/Academic Research';
    case 'IT_SOFTWARE_ENGINEERING':
      return 'IT/Software/Engineering';
    case 'MANUFACTURE_AGRICULTURE':
      return 'Manufacture/Agriculture';
    case 'ARTS_DESIGN':
      return 'Arts/Design';
    case 'FINANCE_BANKING':
      return 'Finance/Banking';
    case 'PUBLIC_SERVICE':
      return 'Public Service/Government';
    case 'HEALTHCARE_MEDICAL':
      return 'Healthcare/Medical Service';
    case 'BUSINESS_SALES':
      return 'Business/Sales';
    case 'LEGAL_CONSULTING':
      return 'Legal/Consulting';
    case 'OTHERS':
      return 'Others';
    default:
      return 'null';
  }
};

/**
 *
 * @param visaInfo 유저가 보는 visaInfo
 * @returns 서버에 보내는 visaInfo
 */
export const convertVisaInfoToRequest = (visaInfo: string): visaType => {
  switch (visaInfo) {
    case 'Diplomatic/Official(A-1,A-2)':
      return 'DIPLOMATIC_OFFICIAL';
    case 'Visa Exempted(B)':
      return 'VISA_EXEMPTED';
    case 'Journalism/Religious Affairs(C-1, D-5, D-6)':
      return 'JOURNALISM';
    case 'Short Term Visit(C-2, C-3)':
      return 'SHORT_TEM_VISIT';
    case 'Study(D-2)':
      return 'STUDY';
    case 'Trainee(D-3,D-4)':
      return 'TRAINEE';
    case 'Intra-Company Transfer(D-7)':
      return 'INTRA_COMPANY_TRANSFER';
    case 'Professional(C-4, D-1, D-8, D-9, D-10, E-1, E-2, E-3, E-4, E-5, E-6, E-7)':
      return 'PROFESSIONAL';
    case 'Non-Professional(E-8, E-9, E-10)':
      return 'NON_PROFESSIONAL';
    case 'Working Holiday(H-1)':
      return 'WORKING_HOLIDAY';
    case 'Work and Visit(H-2)':
      return 'WORK_VISIT';
    case 'Familiy Visitor/Dependent Family(F-1, F-2, F-3)':
      return 'FAMILY_VISITOR';
    case 'Overseas Korean(F-4)':
      return 'OVERSEA_KOREAN';
    case 'Permanent Residence(F-5)':
      return 'PERMANENT_RESIDENCE';
    case 'Marrige Migrant(F-6)':
      return 'MARRIAGE_MIGRANT';
    case 'Others(G-1)':
      return 'OTHERS';
    default:
      return 'OTHERS';
  }
};

/**
 *
 * @param visaType 서버에서 받는 visaType
 * @returns 유저가 보는 visaType
 */
export const convertRequestToVisaInfo = (visaType: string): string => {
  switch (visaType) {
    case 'DIPLOMATIC_OFFICIAL':
      return 'Diplomatic/Official(A-1,A-2)';
    case 'VISA_EXEMPTED':
      return 'Visa Exempted(B)';
    case 'JOURNALISM':
      return 'Journalism/Religious Affairs(C-1, D-5, D-6)';
    case 'SHORT_TEM_VISIT':
      return 'Short Term Visit(C-2, C-3)';
    case 'STUDY':
      return 'Study(D-2)';
    case 'TRAINEE':
      return 'Trainee(D-3,D-4)';
    case 'INTRA_COMPANY_TRANSFER':
      return 'Intra-Company Transfer(D-7)';
    case 'PROFESSIONAL':
      return 'Professional(C-4, D-1, D-8, D-9, D-10, E-1, E-2, E-3, E-4, E-5, E-6, E-7)';
    case 'NON_PROFESSIONAL':
      return 'Non-Professional(E-8, E-9, E-10)';
    case 'WORKING_HOLIDAY':
      return 'Working Holiday(H-1)';
    case 'WORK_VISIT':
      return 'Work and Visit(H-2)';
    case 'FAMILY_VISITOR':
      return 'Familiy Visitor/Dependent Family(F-1, F-2, F-3)';
    case 'OVERSEA_KOREAN':
      return 'Overseas Korean(F-4)';
    case 'PERMANENT_RESIDENCE':
      return 'Permanent Residence(F-5)';
    case 'MARRIAGE_MIGRANT':
      return 'Marrige Migrant(F-6)';
    case 'OTHERS':
      return 'Others(G-1)';
    default:
      return 'null';
  }
};

/**
 *
 * @param whereType 서버에서 받는 whereType
 * @returns 유저가 보는 whereType
 */
export const convertRequestToWhereType = (whereType: string): string | undefined => {
  switch (whereType) {
    case 'UNIVERSITY':
      return 'University';
    case 'ADDRESS':
      return 'Address';
    case 'STATION':
      return 'Station';
    default:
      return undefined;
  }
};

export const convertRoomTypeToRequest = (roomType: string): roomType => {
  switch (roomType) {
    case 'Studio':
      return 'STUDIO';
    case 'Sharehouse':
      return 'SHAREHOUSE';
    case 'Officetel':
      return 'OFFICETEL';
    case 'Others':
      return 'OTHERS';
    default:
      return 'OTHERS';
  }
};

export const convertRequestToRoomType = (roomType: string): string => {
  switch (roomType) {
    case 'STUDIO':
      return 'Studio';
    case 'SHAREHOUSE':
      return 'Sharehouse';
    case 'OFFICETEL':
      return 'Officetel';
    case 'OTHERS':
      return 'Others';
    default:
      return 'null';
  }
};

export const convertRequestToFurnitureType = (furnitureType: furnitureType): string => {
  switch (furnitureType) {
    case 'GAS_STOVE':
      return 'Gas Stove';
    case 'INDUCTION':
      return 'Induction';
    case 'REFRIGERATOR':
      return 'Refrigerator';
    case 'WASHING_MACHINE':
      return 'Washing Machine';
    case 'MICROWAVE_OVEN':
      return 'Microwave Oven';
    case 'AIR_CONDITIONING':
      return 'Air Conditioning';
    case 'DRYER':
      return 'Dryer';
    case 'TABLE':
      return 'Table';
    case 'TV':
      return 'TV';
    case 'WIFI':
      return 'Wifi';
    case 'WARDROBE':
      return 'Wardrobe';
    case 'SHELF':
      return 'Shelf';
    case 'DRAWER':
      return 'Drawer';
    case 'SHOE_RACK':
      return 'Shoe Rack';
    case 'BED':
      return 'Bed';
    default:
      return 'null';
  }
};

export const convertRequestToMonth = (month: string): string => {
  switch (month) {
    case '01':
      return 'JAN';
    case '02':
      return 'FEB';
    case '03':
      return 'MAR';
    case '04':
      return 'APR';
    case '05':
      return 'MAY';
    case '06':
      return 'JUN';
    case '07':
      return 'JUL';
    case '08':
      return 'AUG';
    case '09':
      return 'SEP';
    case '10':
      return 'OCT';
    case '11':
      return 'NOV';
    case '12':
      return 'DEC';
    default:
      return 'null';
  }
};

export const convertMonthToRequest = (month: string): string => {
  switch (month) {
    case 'JAN':
      return '01';
    case 'FEB':
      return '02';
    case 'MAR':
      return '03';
    case 'APR':
      return '04';
    case 'MAY':
      return '05';
    case 'JUN':
      return '06';
    case 'JUL':
      return '07';
    case 'AUG':
      return '08';
    case 'SEP':
      return '09';
    case 'OCT':
      return '10';
    case 'NOV':
      return '11';
    case 'DEC':
      return '12';
    default:
      return 'null';
  }
};

export const convertNumToCountryCode = (num: string): string => {
  return COUNTRY_CODE_OPTIONS.find(option => removeWhitespace(option, 'NUM') === num) || '';
};

export const convertRequestToContractStatusType = (contractStatusType: string): string => {
  switch (contractStatusType) {
    case 'AWAITING':
      return 'Awaiting Confirmation';
    case 'CONFIRMED':
      return 'Confirmed';
    case 'WITHDRAWN':
      return 'Withdrawn';
    case 'REJECTED':
      return 'Rejected';
    default:
      return 'null';
  }
};

export const convertRequestToOrderStatusType = (orderStatusType: string): string => {
  switch (orderStatusType) {
    case 'PROCESSING':
      return 'Processing';
    case 'COMPLETED':
      return 'Completed';
    case 'CANCELED':
      return 'Canceled';
    default:
      return 'null';
  }
};

export const convertRequestToPaymentType = (paymentType: string): string => {
  switch (paymentType) {
    case 'MONTHLY':
      return 'Monthly Payment';
    case 'LUMP_SUM':
      return 'Lump-sum Payment';
    default:
      return '-';
  }
};

export const convertRequestToFacilityType = (facilityType: string): string => {
  switch (facilityType) {
    case 'ELEVATOR':
      return 'Elevator';
    case 'CCTV':
      return 'CCTV';
    case 'ENTRY_DOORLOCK':
      return 'Entry Doorlock';
    default:
      return '-';
  }
};

export const convertRequestToRentExcludesType = (rentExcludesType: string): string => {
  switch (rentExcludesType) {
    case 'ELECTRICITY':
      return 'Electricity';
    case 'GAS':
      return 'Gas';
    case 'WATER':
      return 'Water';
    case 'INTERNET':
      return 'Internet';
    default:
      return '-';
  }
};

export const convertRentExcludesList = (rentExcludesList: string[]): string => {
  if (rentExcludesList.length === 0) {
    return '-';
  }

  return rentExcludesList.map(rentExcludes => convertRequestToRentExcludesType(rentExcludes)).join(', ');
};

export const convertRequestToCommentType = (commentType: string): string => {
  switch (commentType) {
    case 'COMMENT1':
      return 'Only women can move in.';
    case 'COMMENT2':
      return 'It is close to the university shuttle bus stop.';
    case 'COMMENT3':
      return "It's close to the university shuttle bus stop.";
    case 'COMMENT4':
      return 'It has good view.';
    case 'COMMENT5':
      return 'It has good natural lighting.';
    case 'COMMENT6':
      return 'The landlord takes good care of it.';
    case 'COMMENT7':
      return "There are parks and trails nearby, so it's great for a walk.";
    case 'COMMENT8':
      return "It's a newly built building.";
    case 'COMMENT9':
      return 'The cleaning may not be perfect, cleaning before moving in is recommended.';
    case 'COMMENT10':
      return "It's a semi-basement unit, but from the outside, it appears to be on the first floor.";
    default:
      return '-';
  }
};

export const convertSubwayStationKorToEng = (subway: string): string => {
  if (stationData.hasOwnProperty(subway)) {
    return stationData[subway as keyof typeof stationData];
  }

  return subway;
};

export const convertSubwayStationEngToKor = (subway: string): string => {
  const station = Object.entries(stationData).find(([, value]) => value === subway);

  return station ? station[0] : subway;
};

export const convertRequestToHeatingType = (heatingType: string): string => {
  switch (heatingType) {
    case 'INDIVIDUAL':
      return 'Individual heating';
    case 'CENTRAL':
      return 'Central heating';
    default:
      return '-';
  }
};

export const convertRequestToBuildingFrontType = (buildingFrontType: string): string => {
  switch (buildingFrontType) {
    case 'EXIST':
      return 'Exist';
    case 'NOT_EXIST':
      return "Doesn't exist";
    case 'UNKNOWN':
      return 'Unable to confirm';
    default:
      return '-';
  }
};

export const convertRequestToWindowDirectionType = (windowDirectionType: string): string => {
  switch (windowDirectionType) {
    case 'NORTH':
      return 'North';
    case 'NORTHEAST':
      return 'Northeast';
    case 'EAST':
      return 'East';
    case 'SOUTHEAST':
      return 'Southeast';
    case 'SOUTH':
      return 'South';
    case 'SOUTHWEST':
      return 'Southwest';
    case 'WEST':
      return 'West';
    case 'NORTHWEST':
      return 'Northwest';
    case 'UNABLE':
      return 'Unable to confirm';
    default:
      return '-';
  }
};

export const convertRequestToLanguage = (language: string): string => {
  switch (language) {
    case 'ENGLISH':
      return 'English';
    case 'KOREAN':
      return 'Korean';
    case 'CHINESE':
      return 'Chinese';
    case 'JAPANESE':
      return 'Japanese';
    case 'VIETNAMESE':
      return 'Vietnamese';
    default:
      return '-';
  }
};

export const convertRequestToUniversity = (univ: string): string => {
  switch (univ) {
    case 'SNU':
      return 'Seoul National University';
    case 'SSU':
      return 'Soongsil University';
    case 'CAU':
      return 'Chung-Ang University';
    case 'SKKU':
      return 'Sungkyunkwan University';
    case 'KOREA':
      return 'Korea University';
    default:
      return '-';
  }
};

export const convertRequestToUniversityKorean = (univ: string): string => {
  switch (univ) {
    case 'SNU':
      return '서울대학교';
    case 'SSU':
      return '숭실대학교';
    case 'CAU':
      return '중앙대학교';
    case 'SKKU':
      return '성균관대학교';
    case 'KOREA':
      return '고려대학교';
    default:
      return '-';
  }
};
