import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './index.css';
import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import packageJson from '../package.json';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import LoadingSpinner from 'components/common/LoadingSpinner';
import {ModalProvider} from 'context/ModalContext';
import Modals from 'pages/home/Modals';
import BaseQueryClientProvider from 'BaseQueryClientProvider';
import {RouteProvider} from 'context/RouterContext';
import {RecoilRoot} from 'recoil';

console.log(`%cNE(O)RDINARY Project Name : ${packageJson.name}`, 'color:yellow');
console.log(`%cNE(O)RDINARY Project Version : ${packageJson.version}`, 'color:yellow');
console.log(`%cNE(O)RDINARY Project Made by : ${packageJson.author}`, 'color:yellow');

console.log(`
███╗   ██╗███████╗ ██╗ ██████╗ ██╗ ██████╗ ██████╗ ██╗███╗   ██╗ █████╗ ██████╗ ██╗   ██╗
████╗  ██║██╔════╝██╔╝██╔═══██╗╚██╗██╔══██╗██╔══██╗██║████╗  ██║██╔══██╗██╔══██╗╚██╗ ██╔╝
██╔██╗ ██║█████╗  ██║ ██║   ██║ ██║██████╔╝██║  ██║██║██╔██╗ ██║███████║██████╔╝ ╚████╔╝
██║╚██╗██║██╔══╝  ██║ ██║   ██║ ██║██╔══██╗██║  ██║██║██║╚██╗██║██╔══██║██╔══██╗  ╚██╔╝
██║ ╚████║███████╗╚██╗╚██████╔╝██╔╝██║  ██║██████╔╝██║██║ ╚████║██║  ██║██║  ██║   ██║
╚═╝  ╚═══╝╚══════╝ ╚═╝ ╚═════╝ ╚═╝ ╚═╝  ╚═╝╚═════╝ ╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝
`);

const loading = (
  <div className='flex h-screen w-screen items-center justify-center'>
    <LoadingSpinner />
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

const Page404 = React.lazy(() => import('./pages/page404/Page404'));
const Page500 = React.lazy(() => import('./pages/page500/Page500'));

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <BrowserRouter>
    <BaseQueryClientProvider>
      <RecoilRoot>
        <ModalProvider>
          <RouteProvider>
            <Suspense fallback={loading}>
              <Routes>
                <Route path='/404' element={<Page404 />} />
                <Route path='/500' element={<Page500 />} />
                <Route path='/*' element={<DefaultLayout />} />
              </Routes>
              <Modals />
            </Suspense>
          </RouteProvider>
        </ModalProvider>
      </RecoilRoot>
    </BaseQueryClientProvider>
  </BrowserRouter>,
);
