import Button from 'components/button/Button';
import {useModal} from 'context/ModalContext';
import React from 'react';

const ResetStep2 = () => {
  const {openModal, MODAL_TYPES} = useModal();

  const handleLogin = () => {
    openModal(MODAL_TYPES.LOGIN);
  };

  return (
    <div className='mx-30 my-10 flex w-[309px] flex-col items-center gap-y-6.25'>
      <div className='flex w-full flex-col items-center gap-y-1.25'>
        <img src='/images/illusts/il_check.png' alt='reset' className='h-[54px] w-[54px] object-cover' />
        <h1 className='text-t4'>Reset Password</h1>
        <p className='text-center text-c2 text-gray-40'>
          Your new password has been successfully set.
          <br />
          Click the button below to log in.
        </p>
      </div>
      <Button text='Log in' shape='SQUARE' onClick={handleLogin} />
    </div>
  );
};

export default ResetStep2;
