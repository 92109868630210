import React, {useEffect, useRef, useState} from 'react';
import {ReactComponent as DownArrow} from 'assets/icons/ic_triangle.svg';

interface DropdownContainerProps {
  label?: string;
  placeholder?: string;
  error?: string;
  options: string[];
  value: string;
  setValue: (value: string) => void;
  className?: string;
  isDisabled?: boolean;
  initialValue?: string;
}

const DropdownContainer = ({
  label,
  placeholder = 'Select',
  error,
  options,
  value,
  setValue,
  className = 'w-full',
  isDisabled,
  initialValue = '',
}: DropdownContainerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const borderAndBgColor = () => {
    const isActive = value !== '';
    const isError = error !== undefined && error !== '';

    if (isError) return 'border-error bg-white';
    if (isDisabled) return 'border-gray-20 bg-gray-20';
    if (isActive) return 'border-gray-30 bg-white';
    return 'border-gray-20 bg-gray-0';
  };

  const handleToggleOpen = () => {
    if (value === '' && !!initialValue && !isDisabled && !isOpen) {
      setValue(initialValue);
    }

    !isDisabled && setIsOpen(prev => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className={`relative flex flex-col gap-y-0.5 ${className}`}>
      <div className='flex w-full flex-col gap-y-2'>
        {label !== undefined && <span className='text-c2'>{label}</span>}
        <div ref={dropdownRef} className={`relative w-full`}>
          <div
            className={`flex h-11 w-full cursor-pointer items-center gap-x-1 rounded border p-2.5 ${borderAndBgColor()}`}
            onClick={handleToggleOpen}
          >
            <span className={`flex-1 text-c4 ${value !== '' ? 'text-black' : 'text-gray-30'}`}>
              {value === '' ? placeholder : value}
            </span>
            <DownArrow
              width={24}
              height={24}
              className={`${isOpen ? 'rotate-180' : ''} transform text-[#ACACAC] duration-150`}
            />
          </div>
          {isOpen && (
            <div className='absolute z-10 w-full pb-5 pt-2.5'>
              <ul className='flex max-h-48 w-full flex-col overflow-y-auto rounded-md bg-white shadow-md'>
                {options.map(option => (
                  <li
                    key={option}
                    className='w-full cursor-pointer px-4 py-3 text-c4 text-gray-50 hover:bg-gray-0'
                    onClick={() => {
                      setValue(option);
                      setIsOpen(false);
                    }}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <span className='h-4 text-c4 text-error'>{error}</span>
    </div>
  );
};

export default DropdownContainer;
