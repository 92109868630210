import Button from 'components/button/Button';
import React, {useState} from 'react';
import InputContainer from 'components/input/InputContainer';
import {removeWhitespace} from 'utils/utility';
import ContentModalHeader from 'components/modal/ContentModalHeader';
import {usePostVerifyCode} from 'apis/handler/auth/usePostVerifyCode';
import {usePostEmailCertification} from 'apis/handler/auth/usePostEmailCertification';
import FixedLoading from 'components/common/FixedLoading';

interface SignupStep2Props {
  email: string;
  onClickNext: () => void;
}

const SignupStep2 = ({email, onClickNext}: SignupStep2Props) => {
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');

  const {mutateAsync: tryVerifyCode, isPending: verifyCodeIsPending} = usePostVerifyCode({
    onSuccess: data => {
      console.log(data);
      onClickNext();
    },
    onError: error => {
      if ((error = '인증에 실패하였습니다.')) {
        setCodeError('The code is incorrect');
      } else {
        setCodeError(error);
      }
    },
  });

  const {mutateAsync: trySendEmailCertification, isPending: sendEmailIsPending} = usePostEmailCertification({
    onSuccess: data => {
      console.log(data);
    },
    onError: error => {
      if (error === '중복된 이메일입니다.') {
        alert('An account already exists with the provided email address.');
      } else {
        alert(error);
      }
    },
  });

  const onChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCodeError('');

    setCode(removeWhitespace(e.target.value, 'NUM'));
  };

  const handleResendEmail = async () => {
    if (sendEmailIsPending) return;
    try {
      await trySendEmailCertification(email);
    } catch (error) {
      console.error(error);
    }
  };

  const handleNext = async () => {
    if (verifyCodeIsPending) return;

    if (code === '' || code.length < 6) {
      setCodeError('The code is incorrect');
      return;
    }

    try {
      await tryVerifyCode({email, code});
    } catch (error) {
      console.error(error);
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleNext();
    }
  };

  return (
    <div className='mx-45 my-30 flex w-[309px] flex-col gap-y-6.25'>
      <div className='flex w-full flex-col items-center gap-y-4'>
        <ContentModalHeader
          src='/images/illusts/il_mail.png'
          title='Sign up'
          description={`We sent verification code to ${email}.\nPlease check your inbox and enter code below.`}
        />
        <InputContainer
          label='Verification code'
          placeholder='Enter 6-digits code'
          error={codeError}
          value={code}
          onChange={onChangeCode}
          onKeyPress={onKeyPress}
          maxLength={6}
        />
        <p className='mt-4 pt-px text-c2'>
          {`Didn't receive the email?`}{' '}
          <span className='cursor-pointer text-primary underline-offset-2 hover:underline' onClick={handleResendEmail}>
            Resend email
          </span>
        </p>
      </div>
      <Button text='Continue' shape='SQUARE' onClick={handleNext} disabled={verifyCodeIsPending} />
      {(verifyCodeIsPending || sendEmailIsPending) && <FixedLoading />}
    </div>
  );
};

export default SignupStep2;
