import {z} from 'zod';

export type loginResponseType = z.infer<typeof loginResponseSchema>;

export const genderTypeValue = z.enum(['MALE', 'FEMALE']);

export type genderType = z.infer<typeof genderTypeValue>;

export const occupationTypeValue = z.enum([
  'UNDERGRADUATE',
  'GRADUATE',
  'EXCHANGE',
  'EDUCATION',
  'IT_SOFTWARE_ENGINEERING',
  'MANUFACTURE_AGRICULTURE',
  'ARTS_DESIGN',
  'FINANCE_BANKING',
  'PUBLIC_SERVICE',
  'HEALTHCARE_MEDICAL',
  'BUSINESS_SALES',
  'LEGAL_CONSULTING',
  'OTHERS',
]);

export type occupationType = z.infer<typeof occupationTypeValue>;

export const visaTypeValue = z.enum([
  'DIPLOMATIC_OFFICIAL',
  'VISA_EXEMPTED',
  'JOURNALISM',
  'SHORT_TEM_VISIT',
  'STUDY',
  'TRAINEE',
  'INTRA_COMPANY_TRANSFER',
  'PROFESSIONAL',
  'NON_PROFESSIONAL',
  'WORKING_HOLIDAY',
  'WORK_VISIT',
  'FAMILY_VISITOR',
  'OVERSEA_KOREAN',
  'PERMANENT_RESIDENCE',
  'MARRIAGE_MIGRANT',
  'OTHERS',
]);

export type visaType = z.infer<typeof visaTypeValue>;

export type MemberSignUpReqDto = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  genderType: genderType;
  birth: string;
  phoneNumber: string;
  countryCode: string;
  nationality: string;
  occupationType: occupationType;
  visaType: visaType;
};

export type EmailFindReqDto = {
  firstName: string;
  lastName: string;
  birth: string;
  phoneNumber: string;
};

export const findEmailResponseSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  phoneNumber: z.string(),
  email: z.string(),
});

export type findEmailResponseType = z.infer<typeof findEmailResponseSchema>;

export const loginResponseSchema = z.object({
  id: z.number(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  genderType: genderTypeValue,
  nationality: z.string(),
  occupationType: occupationTypeValue,
  visaType: visaTypeValue,
  createdAt: z.string(),
  accessToken: z.string(),
});
