import {useMutation} from '@tanstack/react-query';
import {useRequest} from 'apis/core/Axios';
import {mutationProps} from 'apis/core/commonSchema';

export const usePatchPassword = ({onSuccess, onError}: mutationProps) => {
  const {request} = useRequest();

  return useMutation({
    mutationFn: async ({
      password,
      passwordConfirm,
      resetPasswordToken,
    }: {
      password: string;
      passwordConfirm: string;
      resetPasswordToken: string;
    }) => {
      const res = await request.patch('/auth/member/change-password', {password, passwordConfirm, resetPasswordToken});
      return res;
    },
    onSuccess: data => {
      onSuccess && onSuccess(data);
    },
    onError: error => {
      onError && onError(error);
    },
  });
};
