import {useQueryClient} from '@tanstack/react-query';
import axios from 'axios';
import {useInfoStore} from 'store/useInfoStore';

export const useRequest = () => {
  const queryClient = useQueryClient();
  const {accessToken, resetInfo} = useInfoStore();
  const isAccessToken = accessToken !== '';
  const request = axios.create({
    baseURL: process.env.REACT_APP_API,
    timeout: 20000,
    headers: {
      Accept: 'application/json',
      Authorization: isAccessToken ? `Bearer ${accessToken}` : '',
    },
  });

  request.interceptors.request.use(
    config => {
      config.headers.Authorization = isAccessToken ? `Bearer ${accessToken}` : '';
      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );

  request.interceptors.response.use(
    response => {
      if (response.data.isSuccess) {
        return response.data.result;
      } else {
        return Promise.reject(response.data.message);
      }
    },
    error => {
      console.log(error);
      if (error.status === 403) {
        console.log('403 error');
        resetInfo(queryClient);

        return Promise.reject('로그인이 필요합니다.');
      }
      return Promise.reject(error.code === 'ERR_NETWORK' ? '허용되지 않은 네트워크 접근입니다.' : error);
    },
  );

  return {request};
};
