import React from 'react';
import {ReactComponent as CloseIcon} from 'assets/icons/ic_close.svg';

interface ContentModalProps {
  onClose: () => void;
  closePosition?: string;
  children: React.ReactNode;
}

const ContentModal = ({onClose, children, closePosition}: ContentModalProps) => {
  // 스크롤 방지
  // useEffect(() => {
  //   document.body.style.overflow = 'hidden';
  //   return () => {
  //     document.body.style.overflow = 'auto';
  //   };
  // }, []);

  return (
    // <div className='fixed left-0 top-0 z-20 h-screen w-screen bg-black/50' onClick={onClose}>
    <div
      className='relative flex max-h-[95%] flex-col overflow-y-auto rounded-modal bg-white shadow-abmodal'
      onClick={e => e.stopPropagation()}
    >
      <CloseIcon
        width={34}
        height={34}
        className={`absolute ${closePosition ?? 'right-5 top-5'} cursor-pointer text-[#5F6368]`}
        onClick={onClose}
      />
      {children}
    </div>
    // </div>
  );
};

export default ContentModal;
