import React, {useState} from 'react';

interface InputContainerProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  className?: string;
}

const InputContainer = ({label, error, className = 'w-full', ...props}: InputContainerProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const borderAndBgColor = () => {
    const isActive =
      isFocused ||
      (typeof props.value !== 'string' && props.value !== undefined) ||
      !(typeof props.value === 'string' && props.value === '');
    const isDisabled = props.disabled;
    const isError = error !== undefined && error !== '';

    if (isError) return 'border-error bg-white';
    if (isDisabled) return 'border-gray-20 bg-gray-20';
    if (isActive) return 'border-gray-30 bg-white';
    return 'border-gray-20 bg-gray-0';
  };

  return (
    <div className={`flex flex-col gap-y-0.5 ${className}`}>
      <div className='flex w-full flex-col gap-y-2'>
        {label !== undefined && <span className='text-c3'>{label}</span>}
        <input
          {...props}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className={`h-11 w-full rounded border p-2.5 text-c4 outline-none ${borderAndBgColor()} placeholder:text-gray-30 disabled:text-gray-40`}
        />
      </div>
      <span className='h-4 text-c4 text-error'>{error}</span>
    </div>
  );
};

export default InputContainer;
