import {useModal} from 'context/ModalContext';
import {useNavigate} from 'react-router-dom';
import {useInfoStore} from 'store/useInfoStore';
import {useRouteContext} from 'context/RouterContext';
import {useEffect} from 'react';

export const acceptPath = ['/', '/guide', '/blog', '/listing', '/404', '/500'];

export const useRouteChangeHandler = () => {
  const navigate = useNavigate();
  const {openModal, closeModal, MODAL_TYPES} = useModal();
  const {accessToken, firstName, lastName, email} = useInfoStore();
  const {redirectPath, setRedirectPath} = useRouteContext();

  const handleRouteChange = (path: string, replace?: boolean) => {
    console.log('path', path);
    const isAcceptPath = acceptPath.includes(path) || (path.startsWith('/listing') && path.split('/').length === 2);
    if (!isAcceptPath) {
      console.log('not accept path');
      if (email === '' || firstName === '' || lastName === '' || accessToken === '') {
        setRedirectPath(path);
        openModal(MODAL_TYPES.LOGIN);
        return;
      }
    }
    navigate(path, {replace});
  };

  // 페이지 바뀔 때마다 스크롤 위치 초기화
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const handleLoginSuccess = () => {
    console.log('login success');
    console.log(redirectPath);
    closeModal();
    if (redirectPath) {
      console.log('redirectPath', redirectPath);
      navigate(redirectPath);
      setRedirectPath(null);
    } else {
      console.log('no redirectPath');
      window.location.reload();
    }
  };

  return {handleRouteChange, handleLoginSuccess};
};
