// context/RouteContext.tsx
import React, {createContext, useState, useContext, useEffect} from 'react';

const RouteContext = createContext<{
  redirectPath: string | null;
  setRedirectPath: (path: string | null) => void;
}>({
  redirectPath: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setRedirectPath: () => {},
});

export const RouteProvider = ({children}: {children: React.ReactNode}) => {
  const [redirectPath, setRedirectPath] = useState<string | null>(null);

  useEffect(() => {
    console.log('redirectPath', redirectPath);
  }, [redirectPath]);

  return <RouteContext.Provider value={{redirectPath, setRedirectPath}}>{children}</RouteContext.Provider>;
};

export const useRouteContext = () => useContext(RouteContext);
