import React from 'react';
import {twJoin, twMerge} from 'tailwind-merge';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  shape?: 'ROUNDED' | 'SQUARE';
  theme?: 'PRIMARY' | 'PRIMARY_OUTLINE' | 'BLACK' | 'GRAY' | 'WHITE' | 'WHITE_OUTLINE';
  size?: 'TINY' | 'SMALL' | 'FULL';
  font?: string;
  isLoading?: boolean;
  children?: React.ReactNode;
}

const Button = ({
  text,
  shape = 'SQUARE',
  theme = 'PRIMARY',
  size = 'FULL',
  font = 'text-c1',
  className,
  isLoading,
  children,
  ...props
}: ButtonProps) => {
  const rounded = shape === 'ROUNDED' ? 'rounded-full' : shape === 'SQUARE' ? 'rounded-lg' : '';
  const color = () => {
    switch (theme) {
      case 'PRIMARY':
        return 'bg-primary text-white';
      case 'PRIMARY_OUTLINE':
        return 'border border-primary text-primary bg-white';
      case 'BLACK':
        return 'bg-black text-white';
      case 'GRAY':
        return 'bg-gray-20 text-gray-40';
      case 'WHITE':
        return 'bg-white text-black';
      case 'WHITE_OUTLINE':
        return 'border border-black text-black bg-white';
      default:
        return '';
    }
  };

  const buttonSize = size === 'TINY' ? 'px-3 py-1.5' : size === 'SMALL' ? 'px-3 py-2' : 'p-2.5 w-full';

  return (
    <button
      className={twJoin(
        twJoin(
          'whitespace-pre disabled:bg-gray-20 disabled:text-gray-40',
          rounded,
          color(),
          font,
          isLoading && 'pointer-events-none animate-pulse',
        ),
        twMerge(buttonSize, className),
      )}
      {...props}
    >
      {text}
      {children}
    </button>
  );
};

export default Button;
