import {usePostResetEmail} from 'apis/handler/auth/usePostResetEmail';
import Button from 'components/button/Button';
import FixedLoading from 'components/common/FixedLoading';
import InputContainer from 'components/input/InputContainer';
import ContentModalHeader from 'components/modal/ContentModalHeader';
import {useModal} from 'context/ModalContext';
import React, {useState} from 'react';
import {removeWhitespace} from 'utils/utility';

interface PasswordStep1Props {
  onClickNext: () => void;
}

const PasswordStep1 = ({onClickNext}: PasswordStep1Props) => {
  const {openModal, MODAL_TYPES} = useModal();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const {mutate: trySendPasswordResetEmail, isPending} = usePostResetEmail({
    onSuccess: data => {
      console.log(data);
      onClickNext();
    },
    onError: error => {
      if (error === '일치하는 유저가 없습니다.') {
        alert('No user found with the provided email address.');
      } else {
        alert(error);
      }
    },
  });

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailError('');
    setEmail(removeWhitespace(e.target.value));
  };

  const handleContinue = () => {
    if (isPending) return;

    if (email === '') {
      setEmailError('Please enter your email');
      return;
    }

    trySendPasswordResetEmail(email);
  };

  const handleFindEmail = () => {
    openModal(MODAL_TYPES.FIND_EMAIL);
  };

  return (
    <div className='mx-45 my-30 flex w-[309px] flex-col gap-y-50'>
      <div className='flex w-full flex-col gap-y-6.25'>
        <div className='flex w-full flex-col gap-y-6'>
          <ContentModalHeader
            src='/images/illusts/il_lock.png'
            title='Reset Password'
            description={`Enter the email adress associcated with your account and we’ll send you a link to reset your password`}
          />
          <InputContainer
            label='Email Address'
            placeholder='Enter your email address'
            value={email}
            onChange={onChangeEmail}
            onKeyDown={e => e.key === 'Enter' && handleContinue()}
            error={emailError}
          />
        </div>
        <Button text='Continue' shape='SQUARE' onClick={handleContinue} disabled={isPending} />
      </div>
      <p className='text-center text-c2'>
        {`Forgot your email? `}
        <span className='cursor-pointer text-primary underline-offset-2 hover:underline' onClick={handleFindEmail}>
          Find email
        </span>
      </p>
      {isPending && <FixedLoading />}
    </div>
  );
};

export default PasswordStep1;
