import Button from 'components/button/Button';
import React, {useState} from 'react';
import DropdownContainer from 'components/input/DropdownContainer';
import {
  COUNTRY_CODE_OPTIONS,
  DAY_OPTIONS_BY_MONTH_AND_YEAR,
  GENDER_OPTIONS,
  MONTH_OPTIONS,
  NATIONALITY_OPTIONS,
  OCCUPATION_OPTIONS,
  VISA_INFO_OPTIONS,
  YEAR_OPTIONS,
} from 'config/constant';
import InputContainer from 'components/input/InputContainer';
import {removeWhitespace} from 'utils/utility';
import ContentModalHeader from 'components/modal/ContentModalHeader';
import dayjs from 'dayjs';

interface SignupStep3Props {
  form: {
    gender: string;
    birthday: string;
    countryCode: string;
    phoneNumber: string;
    nationality: string;
    occupation: string;
    visaInfo: string;
  };
  onInputChange: (field: string, value: string) => void;
  onClickNext: () => void;
}

const SignupStep3 = ({form, onInputChange, onClickNext}: SignupStep3Props) => {
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');
  const [genderError, setGenderError] = useState('');
  const [birthdayError, setBirthdayError] = useState('');
  const [countryCodeError, setCountryCodeError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [nationalityError, setNationalityError] = useState('');
  const [occupationError, setOccupationError] = useState('');
  const [visaInfoError, setVisaInfoError] = useState('');

  const onChangePhoneNumber = (field: string, value: string) => {
    setPhoneNumberError('');
    onInputChange(field, removeWhitespace(value, 'NUM'));
  };

  const handleNext = () => {
    if (form.gender === '') {
      setGenderError('Please select Gender');
      return;
    }

    if (month === '' || day === '' || year === '') {
      setBirthdayError('Please select Date of Birth');
      return;
    }

    const birthDay = dayjs(`${year}-${month}-${day}`).format('YYYY-MM-DD');
    onInputChange('birthday', birthDay);

    if (form.countryCode === '') {
      setCountryCodeError('Please select Country Code');
      return;
    }
    onInputChange('countrycode', removeWhitespace(form.countryCode, 'NUM'));

    if (form.phoneNumber === '') {
      setPhoneNumberError('Please enter Phone Number');
      return;
    }
    onInputChange('phoneNumber', removeWhitespace(form.phoneNumber, 'NUM'));

    if (form.nationality === '') {
      setNationalityError('Please enter Nationality');
      return;
    }

    if (form.occupation === '') {
      setOccupationError('Please enter Occupation');
      return;
    }

    if (form.visaInfo === '') {
      setVisaInfoError('Please enter Visa Information');
      return;
    }

    console.log('3 : ', form);
    onClickNext();
  };

  return (
    <div className='m-30 mb-50 flex w-[408px] flex-col gap-y-11'>
      <div className='flex w-full flex-col gap-y-6'>
        <ContentModalHeader
          src='/images/illusts/il_mail.png'
          title='Sign up'
          description='Please Enter Your Personal info'
        />
        <div className='flex w-full flex-col gap-y-px'>
          <DropdownContainer
            className='flex-1'
            label='Gender'
            value={form.gender}
            setValue={value => {
              setGenderError('');
              onInputChange('gender', value);
            }}
            options={GENDER_OPTIONS}
            error={genderError}
          />
          <div className='flex w-full flex-col gap-y-2'>
            <span className='text-c2'>Date of Birth</span>
            <div className='flex w-full gap-x-3.5'>
              <DropdownContainer
                className='flex-1'
                placeholder='Month'
                value={month}
                setValue={value => {
                  setBirthdayError('');
                  setMonth(value);
                }}
                options={MONTH_OPTIONS}
                error=''
              />
              <DropdownContainer
                className='flex-1'
                placeholder='Date'
                value={day}
                setValue={value => {
                  setBirthdayError('');
                  setDay(value);
                }}
                isDisabled={month === ''}
                options={DAY_OPTIONS_BY_MONTH_AND_YEAR(month, year)}
                error=''
              />
              <DropdownContainer
                className='flex-1'
                placeholder='Year'
                value={year}
                initialValue='2000'
                setValue={value => {
                  setBirthdayError('');
                  setYear(value);
                  setDay('');
                }}
                options={YEAR_OPTIONS}
                error=''
              />
            </div>
            <span className='-mt-6 h-4 text-[11px] text-error'>{birthdayError}</span>
          </div>
          <div className='flex w-full gap-x-3.5'>
            <DropdownContainer
              className='flex-1'
              label='Country Code'
              value={form.countryCode}
              setValue={value => {
                setCountryCodeError('');
                onInputChange('countryCode', value);
              }}
              options={COUNTRY_CODE_OPTIONS}
              error={countryCodeError}
            />
            <InputContainer
              className='flex-1'
              placeholder='Enter numbers only'
              label='Phone Number'
              value={form.phoneNumber}
              onChange={e => onChangePhoneNumber('phoneNumber', e.target.value)}
              error={phoneNumberError}
            />
          </div>
          <DropdownContainer
            label='Nationality'
            value={form.nationality}
            setValue={value => {
              setNationalityError('');
              onInputChange('nationality', value);
            }}
            options={NATIONALITY_OPTIONS}
            error={nationalityError}
          />
          <DropdownContainer
            label='Occupation'
            value={form.occupation}
            setValue={value => {
              setOccupationError('');
              onInputChange('occupation', value);
            }}
            options={OCCUPATION_OPTIONS}
            error={occupationError}
          />
          <DropdownContainer
            label='Visa Information'
            value={form.visaInfo}
            setValue={value => {
              setVisaInfoError('');
              onInputChange('visaInfo', value);
            }}
            options={VISA_INFO_OPTIONS}
            error={visaInfoError}
          />
        </div>
      </div>
      <Button text='Continue' shape='SQUARE' onClick={handleNext} />
    </div>
  );
};

export default SignupStep3;
